export const domainNames = {
	GBR: 'gobankingrates',
	GFC: 'gofreecredit',
	IS: 'importantscore',
};

export const metaData = {
	gobankingrates: {
		titles: {
			confirmRequest: 'Verifying Request | GOBankingRates.com',
			deleteDataConfirmation: 'Delete Data Confirmation | GOBankingRates.com',
			doNotSellDataConfirmation: 'Do Not Sell Confirmation | GOBankingRates.com',
			seeDataConfirmation: 'See Data Confirmation | GOBankingRates.com',
			error: 'Something Went Wrong | GOBankingRates.com',
			genericConfirmation: 'Thank You | GOBankingRates.com',
			manageMyData: 'Manage My Data | GOBankingRates.com',
			notFound: '404 Page Not Found | GOBankingRates.com',
			privacyPolicy: 'Privacy Policy & Terms of Use | GOBankingRates.com',
			verifyIdentification: 'Verify ID | GOBankingRates.com',
		},
		description: 'GOBankingRates.com Privacy Policy and Terms of Use',
		favicon: 'faviconGBR.ico',
		gtmId: 'GTM-MWR5LD9',
	},

	gofreecredit: {
		titles: {
			confirmRequest: 'Verifying Request | GOFreeCredit.com',
			deleteDataConfirmation: 'Delete Data Confirmation | GOFreeCredit.com',
			doNotSellDataConfirmation: 'Do Not Sell Confirmation | GOFreeCredit.com',
			seeDataConfirmation: 'See Data Confirmation | GOFreeCredit.com',
			error: 'Something Went Wrong | GOFreeCredit.com',
			genericConfirmation: 'Thank You | GOFreeCredit.com',
			manageMyData: 'Manage My Data | GOFreeCredit.com',
			notFound: '404 Page Not Found | GOFreeCredit.com',
			privacyPolicy: 'Privacy Policy & Terms of Use | GOFreeCredit.com',
			verifyIdentification: 'Verify ID | GOFreeCredit.com',
		},
		description: 'GOFreeCredit.com Privacy Policy and Terms of Use',
		favicon: 'faviconGFC.ico',
		gtmId: 'GTM-T3K96TF',
	},

	importantscore: {
		titles: {
			confirmRequest: 'Verifying Request | ImportantScore.com',
			deleteDataConfirmation: 'Delete Data Confirmation | ImportantScore.com',
			doNotSellDataConfirmation: 'Do Not Sell Confirmation | ImportantScore.com',
			seeDataConfirmation: 'See Data Confirmation | ImportantScore.com',
			error: 'Something Went Wrong | ImportantScore.com',
			genericConfirmation: 'Thank You | ImportantScore.com',
			manageMyData: 'Manage My Data | ImportantScore.com',
			notFound: '404 Page Not Found | ImportantScore.com',
			privacyPolicy: 'Privacy Policy & Terms of Use | ImportantScore.com',
			verifyIdentification: 'Verify ID | ImportantScore.com',
		},
		description: 'ImportantScore.com Privacy Policy and Terms of Use',
		favicon: 'faviconIS.ico',
		gtmId: 'GTM-M2LTQ4B',
	},
};
