import React from 'react';

import GBRLogo from 'components/GBRLogo/GBRLogo';

import { getCurrentDomain, getAppUrlOrigin } from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

const Logo = () => {
  const domain = getCurrentDomain();

  if (domain === domainNames.GBR) {
    return <GBRLogo />;
  }
  return null;
};

const Header = () => {
  const appUrlOrigin = getAppUrlOrigin();

  return (
    <header className="Header">
      <div className="container-fluid Header__wrap">
        <a className="Header__link" href={appUrlOrigin} target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </div>
    </header>
  );
};

export default Header;
