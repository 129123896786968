//
// Static page links
//
// INFO: Make sure you're prepending the appropriate app prefix path to these.
// e.g. `/ecs/static-pages/about`, `/tu/static-pages/faq`
//

export const ABOUT_US = {
	text: 'About Us',
	href: '/static-pages/about',
	target: '_blank',
};

export const ADVERTISER_DISCLOSURE = {
	text: 'Advertiser Disclosure',
	href: '/static-pages/advertiser-disclosure',
	target: '_blank',
};

export const CONTACT_US = {
	text: 'Contact Us',
	href: '/static-pages/contact',
	target: '_blank',
};

export const FAQ = {
	text: 'FAQ',
	href: '/static-pages/faq',
	target: '_blank',
};

export const SECURITY = {
	text: 'Security',
	href: '/static-pages/security',
	target: '_blank',
};

//
// PersonalInformationDataApp (PIDA) links
//
// INFO: Make sure you're appending the appropriate `view` query string param for these.
// e.g. `/do-not-sell?view=ecs`, `/privacy?view=tu`
//

export const PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION = {
	href: '/do-not-sell',
	text: 'Do Not Sell My Personal Information',
};

export const PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE = {
	text: 'Privacy Policy & Terms of Use',
	href: '/privacy',
};
