import React from 'react';

import experianHeader from 'static/images/importantscore-header-experian.svg';
import transUnionHeader from 'static/images/importantscore-header-tu.svg';
import experianLogo from 'static/images/experian-logo.svg';
import transUnionLogo from 'static/images/transunion-logo.svg';

import { getAppUrlOrigin, getViewParameter } from 'utilities/domain-detection/domain-detection';

const ISHeader = () => {
  const appUrlOrigin = getAppUrlOrigin();
  const view = getViewParameter();
  const isTransUnion = view === 'tu';

  return (
    <header className="ISHeader">
      <div className="container-fluid ISHeader__wrap">
        <a className="ISHeader__link" href={appUrlOrigin} target="_blank" rel="noreferrer">
          <img
            alt="Logo for www.importantscore.com"
            className="ISHeader__IS-logo"
            height="48"
            src={isTransUnion ? transUnionHeader : experianHeader}
            width="194"
          />
        </a>

        <div className="ISHeader__logos">
          <img alt="TransUnion logo" height="34" src={transUnionLogo} width="137" />

          {!isTransUnion ? (
            <img alt="Experian logo" height="59" src={experianLogo} width="240" />
          ) : undefined}
        </div>
      </div>
    </header>
  );
};
export default ISHeader;
