import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import Header from 'components/Header/Header';
import ISHeader from 'components/ISHeader/ISHeader';
import GFCHeader from 'components/GFCHeader/GFCHeader';

import GFCFooter from 'components/GFCFooter/GFCFooter';
import GBRFooter from 'components/GBRFooter/GBRFooter';
import ISFooter from 'components/ISFooter/ISFooter';

import { domainNames, metaData } from 'utilities/domain-data/domain-data';
import { getCurrentDomain } from 'utilities/domain-detection/domain-detection';

const FooterComponent = () => {
	const domain = getCurrentDomain();

	switch (domain) {
		case domainNames.GBR:
			return <GBRFooter />;
		case domainNames.GFC:
			return <GFCFooter />;
		case domainNames.IS:
			return <ISFooter />;
		default:
			return <ISFooter />;
	}
};

const HeaderComponent = () => {
	const domain = getCurrentDomain();

	switch (domain) {
		case domainNames.GBR:
			return <Header />;
		case domainNames.GFC:
			return <GFCHeader />;
		case domainNames.IS:
			return <ISHeader />;
		default:
			return <ISHeader />;
	}
};

const withPageLayout = (PageComponent, { pageTitle, isIndexed = false } = {}) => {
	return (props) => {
		useEffect(() => {
			if (pageTitle) {
				document.title = metaData[getCurrentDomain()].titles[pageTitle];
			}
		}, []);

		return (
			<>
				<Helmet>
					<meta name="robots" content={isIndexed ? 'index, follow' : 'noindex, nofollow'} />
				</Helmet>

				<HeaderComponent />

				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<PageComponent {...props} />

				<FooterComponent />
			</>
		);
	};
};

export default withPageLayout;
