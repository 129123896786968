import { domainNames } from 'utilities/domain-data/domain-data';

function parseDomain() {
	let { hostname } = window.location;

	if (['127.0.0.1', '0.0.0.0', 'localhost'].includes(hostname)) {
		hostname = 'stage-member.importantscore.com';
	}

	const substrings = hostname.split('.');

	let subdomain = substrings?.[0];
	if (subdomain !== 'member' && subdomain !== 'stage-member') {
		subdomain = 'stage-member';
	}

	let domain = substrings?.[1];
	const topLevelDomain = substrings?.[2];

	if (!Object.values(domainNames).includes(domain)) {
		domain = 'importantscore';
	}

	return { subdomain, domain, topLevelDomain };
}

export const getViewParameter = () => {
	const { searchParams } = new URL(window.location.href);
	const view = searchParams.get('view');

	if (view === 'ecs' || view === 'tu') {
		return view;
	}

	return 'ecs';
};

export const getCurrentDomain = () => {
	const { domain } = parseDomain();

	return domain;
};

export const getCurrentSubDomain = () => {
	const { subdomain } = parseDomain();

	return subdomain;
};

export const getFormattedDomainName = () => {
	const { domain } = parseDomain();

	switch (domain) {
		case domainNames.GBR:
			return 'GOBankingRates.com';
		case domainNames.GFC:
			return 'GOFreeCredit.com';
		case domainNames.IS:
			return 'ImportantScore.com';
		default:
			return 'ImportantScore.com';
	}
};

export const getFormattedName = () => {
	const formattedDomainName = getFormattedDomainName();
	return formattedDomainName.replace(/\.com$/g, '');
};

export const getIsProduction = () => {
	return getCurrentSubDomain() === 'member';
};

export const getAppUrlOrigin = () => {
	const domain = getCurrentDomain();

	const appSubdomain = getIsProduction() ? 'www' : 'stage-www';

	return `https://${appSubdomain}.${domain}.com`;
};
