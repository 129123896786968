// INFO: Update if the root of the endpoints changes
const ROOT = '';

const ENDPOINTS = {
	confirmRequest: '/action/confirm',
	deleteData: '/action/delete-data',
	doNotSellData: '/action/do-not-sell',
	seeData: '/action/see-data',
};

export default (endpoint) => {
	const mappedEndpoint = ENDPOINTS[endpoint];

	if (mappedEndpoint) {
		return `${ROOT}${mappedEndpoint}`;
	}

	return `${ROOT}${endpoint}`;
};
