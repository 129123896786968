export const GO_BANKING_RATES = {
	text: 'GOBankingRates',
	href: 'https://www.gobankingrates.com',
	rel: 'noreferrer',
	target: '_blank',
};

export const GO_BANKING_RATES_HOW_TO_INCREASE_CREDIT_SCORE = {
	text: 'How To Increase Credit Score?',
	href: 'https://www.gobankingrates.com/credit/credit-score/raise-credit-score-100-points/',
	rel: 'noreferrer',
	target: '_blank',
};

export const GO_BANKING_RATES_WHAT_IS_A_GOOD_CREDIT_SCORE = {
	text: 'What Is A Good Credit Score?',
	href: 'https://www.gobankingrates.com/credit/credit-score/what-is-good-credit-score/',
	rel: 'noreferrer',
	target: '_blank',
};

export const GO_BANKING_RATES_WHAT_IS_THE_HIGHEST_CREDIT_SCORE = {
	text: 'What Is The Highest Credit Score?',
	href: 'https://www.gobankingrates.com/credit/credit-score/what-is-highest-credit-score/',
	rel: 'noreferrer',
	target: '_blank',
};

export const MEMBER_LOGIN = {
	text: 'Member Login',
	href: 'https://usa.experian.com/login/#/index?pc=aff_exp_104',
	rel: 'noreferrer',
	target: '_blank',
};
