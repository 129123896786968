import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const AppStateContext = createContext();

export const AppStateProvider = ({ initialState = {}, children }) => {
	return (
		<AppStateContext.Provider value={useState(initialState)}>{children}</AppStateContext.Provider>
	);
};

AppStateProvider.propTypes = {
	initialState: PropTypes.shape({}),
	children: PropTypes.node,
};

export const useAppState = () => useContext(AppStateContext);
